.grid {
  max-width: $max-page-width;
  grid-template-columns: repeat(12, 1fr);
  padding: 0 $site-padding-mobile;
  column-gap: 0;
  width: 100%;
  margin: 0 auto;

  @media (min-width: $media-breakpoint-md) {
    display: grid;
    padding: 0 $site-padding-tablet;
  }

  @media (min-width: $media-breakpoint-xl) {
    padding: 0 $site-padding-desktop;
  }
}
