.UnitPage {
  width: 100%;
  background-color: #fff;

  &[data-headercolor="theme-dark"] {
    .close-overlay-button {
      color: $color-black;
    }
  }

  .UnitIntroModule {
    text-align: center;
    margin-top: 120px;
    margin-bottom: 50px;

    @media only screen and (min-width: $media-breakpoint-md) {
      margin-bottom: 80px;
    }

    @media only screen and (min-width: $media-breakpoint-xl) {
      margin-bottom: 120px;
    }

    @media only screen and (min-width: $media-breakpoint-xxl) {
      margin-bottom: 160px;
    }

    .wrapper {
      grid-column-start: 2;
      grid-column-end: span 10;
    }

    h3 {
      margin-bottom: 1em;
    }

    h1 {
      margin-bottom: 20px;
    }

    .tutors {
      font-weight: 500;
    }
  }

  .UnitHeroImageModule {
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100%;
    margin-bottom: 100px;

    @media only screen and (min-width: $media-breakpoint-md) {
      margin-bottom: 160px;
    }
  }

  .modules {
    display: flex;
    flex-direction: column;
  }

  &.theme-dark {
    color: $color-white;
    background: $color-off-black;
  }

  .UnitOverviewModule {
    margin-bottom: 100px;

    @media only screen and (min-width: $media-breakpoint-md) {
      margin-bottom: 140px;
    }

    h3 {
      margin-bottom: 15px;

      @media only screen and (min-width: $media-breakpoint-md) {
        margin-bottom: 35px;
      }
    }

    h1 + br,
    h3 + br {
      display: none;
    }

    .brief {
      grid-column-start: 2;
      grid-column-end: span 10;
      margin-bottom: 50px;

      @media only screen and (min-width: $media-breakpoint-lg) {
        grid-column-start: 2;
        grid-column-end: span 5;
        margin-bottom: 0;
      }
    }

    .students-tutors {
      grid-column-start: 2;
      grid-column-end: span 10;

      @media only screen and (min-width: $media-breakpoint-lg) {
        grid-column-start: 8;
        grid-column-end: span 4;
      }
    }

    .students {
      margin-bottom: 60px;
    }

    .students,
    .tutors {
      .title-year {
        margin-bottom: 1em;
      }

      ul {
        column-count: 2;
        margin-bottom: 2em;

        @media only screen and (min-width: $media-breakpoint-md) {
          @include to-grid("width", 10, 8);
        }

        li {
          line-height: 1.4;
          font-weight: normal;
          -webkit-margin-before: 0;
          -webkit-margin-after: 0;
        }

        a,
        span {
          margin-bottom: 0.5em;
          display: inline-block;
        }

        a {
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}
