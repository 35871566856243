.input {
  border: 0;
  height: $input-height;
  padding: 0px ($input-padding * 2) 0 $input-padding;
  opacity: 1;
  background: $color-gray-light;
  position: relative;
  @extend .type-title--small;
  font-family: $wigrum;

  &:focus {
    border-radius: 0;
    outline: none;
    box-shadow: 0 0 transparent;
    border: 1px solid rgba($color-black, 0.05);
  }
}

.dropdown {
  left: 0;
  min-width: 100%;
  transform: translateY(-5px);
  opacity: 0;
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
  top: 100%;
  bottom: auto;
  z-index: 0;
  pointer-events: none;

  .inner-dropdown {
    padding: 0;
    background: $color-gray-light;
    max-height: 500px;
    overflow: auto;

    .theme-dark & {
      background-color: $color-black;
    }

    .theme-light & {
      background-color: $color-gray-light;
    }
  }

  .list {
    display: flex;
    flex-direction: column;

    .list-item {
      &.active .anchor {
        opacity: 1;
        pointer-events: none;
      }

      .anchor {
        position: relative;
        display: flex;

        & > * {
          pointer-events: none;
        }
      }

      .dropdown-subtitle {
        display: block;
        opacity: 0.4;
        margin-top: 5px;
      }
    }

    button {
      padding: ($input-padding / 2) ($input-padding * 2 + 10)
        ($input-padding / 2) $input-padding;

      &:hover {
        background: $color-white;
      }

      span {
        pointer-events: none;
      }
    }

    .list-item:first-child {
      button {
        padding-top: $input-padding;
      }
    }

    .list-item:last-child {
      button {
        padding-bottom: $input-padding;
      }
    }
  }
}
