.AboutPage {
  width: 100%;
  padding-top: 120px;
  background-color: $color-white;
  color: $color-black;

  .close-overlay-button {
    color: $color-black;
  }

  &.theme-dark {
    color: $color-white;

    .close-overlay-button {
      color: $color-white;
    }
  }

  .about-intro {
    text-align: center;
    margin-bottom: 50px;

    .headline {
      text-align: center;
      margin-bottom: 25px;
    }

    .description {
      max-width: 1340px;
      text-align: center;
      margin-bottom: 30px;
    }

    .wrapper {
      grid-column-start: 2;
      grid-column-end: span 10;
    }
  }

  .hero-image {
    width: 100%;
    margin-bottom: 60px;

    .wrapper {
      grid-column-start: 1;
      grid-column-end: span 12;
    }

    img {
      width: 100%;
      opacity: 0;
      transition: 1s opacity;

      &.lazyloaded {
        opacity: 1;
        transition: 1s opacity;
      }
    }

    .caption {
      grid-column-start: 2;
      grid-column-end: span 10;
      color: $color-gray;
      margin-top: 15px;
    }
  }

  .intro {
    width: 100%;

    .wrapper {
      grid-column-start: 2;
      grid-column-end: span 10;
    }

    .introduction {
      width: 100%;
      margin-bottom: 50px;

      @media (min-width: $media-breakpoint-xl) {
        margin-bottom: 100px;
      }
    }
  }

  .authors {
    margin-bottom: 50px;
    color: $color-gray;
  }

  .content {
    .left {
      //grid-column-start: 0;
      grid-column-end: span 12;

      .disclaimer {
        margin-bottom: 80px;

        .title {
          margin-bottom: 20px;
        }

        .bod {
          color: $color-gray;
        }
      }
    }

    .right {
      //grid-column-start: 2;
      grid-column-end: span 12;
      margin-bottom: 140px;

      .credits {
        display: flex;
        justify-content: space-between;

        .col {
          width: 50%;
          @extend .type-p--small;

          h1,
          h2,
          h3,
          h4 {
            margin-top: 30px;
            margin-bottom: 0.3em;

            &:first-of-type {
              margin-top: 0;
            }

            & + br {
              display: none;
            }
          }

          &:first-of-type {
            margin-right: 30px;
          }

          p {
            @extend .type-p--small;

            & + br {
              display: none;
            }
          }
        }
      }

      .additional {
        max-width: 414px;
        line-height: 140%;
        //color: $color-black;
        @extend .type-p;
        margin-top: 2em;
      }

      .contact-title {
        margin-top: 3em;
      }
    }
  }

  .supporters {
    margin-bottom: 60px;

    .supporters-title {
      margin-top: 3em;
    }

    ul {
      column-count: 2;
      margin-bottom: 40px;
    }

    li {
      display: inline-block;
      width: 100%;
    }

    h4 {
      margin-bottom: 0.3em;
    }

    // 1 column exception for ss21
    //img {
    //  max-width: 100%;
    //}
    //
    //.images {
    //  column-count: 2;
    //
    //  li {
    //    margin-bottom: 30px;
    //  }
    //}

    img {
      width: 80%;
      max-width: 170px;
    }

    .images {
      column-count: 1;

      li {
        margin-bottom: 30px;
      }
    }
  }

  @media (min-width: $media-breakpoint-md) {
    padding-top: 160px;

    .about-intro {
      margin-bottom: 80px;

      .headline {
        margin-bottom: 30px;
      }

      .description {
        margin-bottom: 35px;
      }
    }

    .caption {
      margin-top: 30px;
      grid-column-start: 2;
      grid-column-end: span 5;
    }

    .content {
      .left {
        grid-column-start: 2;
        grid-column-end: span 5;
      }

      .right {
        grid-column-start: 8;
        grid-column-end: span 4;
        margin-bottom: 0px;
      }
    }
  }

  @media (min-width: $media-breakpoint-lg) {
    padding-top: 220px;

    .headline {
      margin-bottom: 60px;
    }
  }

  @media (min-width: $media-breakpoint-xl) {
    padding-top: 250px;

    .hero-image {
      margin-bottom: 150px;
    }

    .about-intro {
      margin-bottom: 120px;

      .headline {
        margin-bottom: 60px;
      }

      .description {
        margin-bottom: 50px;
      }
    }

    .authors {
      margin-bottom: 150px;
    }
  }
}
