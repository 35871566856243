.FooterTemplate {
  width: 80%;
  bottom: 0;
  left: 10%;
  text-align: center;
  padding-bottom: 30px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $media-breakpoint-md) {
    width: 100%;
    left: 0;
    padding-bottom: 60px;
    margin-top: 80px;
  }

  @media (min-width: $media-breakpoint-lg) {
    margin-top: 100px;
  }

  a,
  .copyright {
    color: $color-black;

    .theme-dark & {
      color: $color-white;
    }
  }

  .links {
    display: inline-block;

    ul {
      display: block;
      margin-bottom: 10px;

      @media (min-width: $media-breakpoint-md) {
        display: flex;
      }

      li {
        display: inline-block;
      }

      a {
        padding: 1px 8px;
        display: inline-block;

        @media (min-width: $media-breakpoint-sm) {
          padding: 5px 8px;
        }

        @media (min-width: $media-breakpoint-md) {
          padding: 10px 12px;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .copyright {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 7px;

    @media (min-width: $media-breakpoint-sm) {
      margin-bottom: 0;
    }
  }

  .MainMenu & {
    position: absolute;
    margin-top: 0;

    a,
    .copyright {
      color: $color-white;
    }
  }
}
