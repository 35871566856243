.ErrorTemplate {
  color: $color-white;
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  div {
    display: flex;
    flex-direction: column;
    padding: $site-padding-mobile;
  }

  span {
    margin-bottom: 2em;
  }

  h1 {
    margin-bottom: 0.5em;
  }

  p {
    max-width: 700px;
    margin: 0 auto 3.5em auto;
    display: inline-block;
  }

  a {
    color: $color-white;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
