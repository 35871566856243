.SectionModule {
  margin-bottom: 0;
  padding: 50px 0 100px;
  border-top: 1px solid rgba($color-black, 0.1);

  .theme-dark & {
    border-color: rgba($color-white, 0.1);
  }

  @media only screen and (min-width: $media-breakpoint-xxl) {
    padding: 50px 0 100px;
  }

  .number {
    grid-column-start: 2;
    grid-column-end: span 2;
  }

  .title {
    grid-column-start: 2;
    grid-column-end: span 6;

    @media only screen and (min-width: $media-breakpoint-md) {
      grid-column-start: 6;
    }
  }
}
