.ProgrammesPage {
  width: 100%;
  padding-top: 120px;
  background-color: $color-white;
  color: $color-black;

  .close-overlay-button {
    color: $color-black;
  }

  &.theme-dark {
    color: $color-white;

    .close-overlay-button {
      color: $color-white;
    }

    .content .right .links {
      color: $color-white;
      a {
        color: $color-white;
      }
    }
  }

  .programmes-intro {
    text-align: center;
    margin-bottom: 50px;

    .headline {
      text-align: center;
      margin-bottom: 25px;
    }

    .description {
      max-width: 1340px;
      text-align: center;
      margin-bottom: 30px;
    }

    .wrapper {
      grid-column-start: 2;
      grid-column-end: span 10;
    }
  }

  .hero-image {
    width: 100%;
    margin-bottom: 60px;

    .wrapper {
      grid-column-start: 1;
      grid-column-end: span 12;
    }

    img {
      width: 100%;
      opacity: 0;
      transition: 1s opacity;

      &.lazyloaded {
        opacity: 1;
        transition: 1s opacity;
      }
    }

    .caption {
      grid-column-start: 2;
      grid-column-end: span 10;
      color: $color-gray;
      margin-top: 15px;
    }
  }

  .intro {
    width: 100%;

    .wrapper {
      grid-column-start: 2;
      grid-column-end: span 10;
    }

    .introduction {
      width: 100%;
      color: $color-black;
      margin-bottom: 50px;

      @media (min-width: $media-breakpoint-xl) {
        margin-bottom: 100px;
      }
    }
  }

  .content {
    a:hover {
      color: $color-black;
    }

    h1 {
      @extend .type-title--med;
      & + br {
        display: none;
      }

      margin-top: 50px;
      margin-bottom: 30px;

      // Title/H3 – Tablet
      @media (min-width: $media-breakpoint-md) {
        margin-top: 50px;
        margin-bottom: 40px;
      }

      // Title/H3 – Desktop
      @media (min-width: $media-breakpoint-xl) {
        margin-top: 65px;
        margin-bottom: 65px;
      }

      // Title/H3 – Desktop Large
      @media (min-width: $media-breakpoint-xxl) {
        margin-top: 80px;
        margin-bottom: 65px;
      }
    }

    h2 {
      @extend .type-title--med;
      margin-bottom: 1.6em;
    }

    h3 {
      @extend .type-title--body;
    }

    ul {
      padding-left: 1.5em;
      li {
        @extend .type-p;
      }

      li:before {
        content: "—";
        position: absolute;
        margin-left: -1.5em;
      }
    }

    .left {
      //grid-column-start: 0;
      grid-column-end: span 12;

      .body {
        h2,
        h3,
        h4 {
          margin-top: 25px;
        }

        h1:first-of-type,
        h1 + br + h2,
        h1 + br + h3,
        h1 + br + h4 {
          margin-top: 0;
        }

        a {
          font-weight: 500;
        }

        margin-bottom: 60px;
      }
    }

    .right {
      grid-column-end: span 12;
      margin-bottom: 140px;

      .links {
        max-width: 414px;
        line-height: 140%;
        color: $color-black;
        @extend .type-p;

        a {
          color: $color-black;
          text-decoration: underline;
          @extend .type-link--small;
        }
      }
    }
  }

  @media (min-width: $media-breakpoint-md) {
    padding-top: 160px;

    .programmes-intro {
      margin-bottom: 80px;

      .headline {
        margin-bottom: 30px;
      }

      .description {
        margin-bottom: 35px;
      }
    }

    .caption {
      margin-top: 30px;
      grid-column-start: 2;
      grid-column-end: span 5;
    }

    .content {
      .left {
        grid-column-start: 2;
        grid-column-end: span 5;
      }

      .right {
        grid-column-start: 8;
        grid-column-end: span 4;
        margin-bottom: 0px;
      }
    }
  }

  @media (min-width: $media-breakpoint-lg) {
    padding-top: 220px;

    .headline {
      margin-bottom: 60px;
    }
  }

  @media (min-width: $media-breakpoint-xl) {
    padding-top: 250px;

    .intro .introduction {
      margin-bottom: 150px;
    }

    .hero-image {
      margin-bottom: 150px;
    }

    .programmes-intro {
      margin-bottom: 120px;

      .headline {
        margin-bottom: 60px;
      }

      .description {
        margin-bottom: 50px;
      }
    }
  }
}
