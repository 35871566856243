.Autocomplete {
  position: relative;

  &.active {
    overflow: visible;

    .dropdown {
      opacity: 1;
      transform: translateY(0);
      pointer-events: auto;
    }
  }

  button {
    width: 100%;
    text-align: left;
    white-space: nowrap;
  }

  input {
    width: 330px;
  }

  .input-wrapper {
    position: relative;

    &::after {
      content: "";
      width: 20px;
      height: 20px;
      display: block;
      background: url("../../assets/svg/embedded/search.svg") no-repeat center
        center;
      background-size: contain;
      position: absolute;
      top: 13px;
      right: 17px;
    }
  }

  .dropdown {
    position: absolute;
    right: 0;
  }
}
