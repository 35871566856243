.type-title {
  // Title/H1: .type-title--xlarge
  &--xlarge {
    font-size: 40px;
    line-height: 100%;
    font-weight: 500;

    // Title/H1 – Tablet
    @media (min-width: $media-breakpoint-md) {
      font-size: 64px;
    }

    // Title/H1 – Desktop
    @media (min-width: $media-breakpoint-xl) {
      font-size: 90px;
    }

    // Title/H1 – Desktop Large
    @media (min-width: $media-breakpoint-xxl) {
      font-size: 120px;
    }
  }

  // Title/H2: .type-title--large
  &--large {
    // Title/H2 – Desktop Large
    @media (min-width: $media-breakpoint-xxl) {
      font-weight: 500;
      font-size: 90px;
      line-height: 100%;
      letter-spacing: -0.01em;
    }
  }

  // Title/H3: .type-title--med
  &--med {
    font-weight: 500;
    font-size: 18px;
    line-height: 125%;

    // Title/H3 – Tablet
    @media (min-width: $media-breakpoint-md) {
      font-size: 24px;
    }

    // Title/H3 – Desktop
    @media (min-width: $media-breakpoint-xl) {
      font-size: 32px;
    }

    // Title/H3 – Desktop Large
    @media (min-width: $media-breakpoint-xxl) {
      font-size: 40px;
    }
  }

  // Title/Body title: .type-title--body
  &--body {
    font-weight: 500;
    font-size: 15px;

    // Title/Body title – Tablet
    @media (min-width: $media-breakpoint-md) {
      font-size: 18px;
    }

    // Title/Body title – Desktop
    @media (min-width: $media-breakpoint-xl) {
      font-size: 20px;
    }

    // Title/Body title – Desktop large
    @media (min-width: $media-breakpoint-xxl) {
      font-size: 22px;
    }
  }

  // Title/Small paragraph title: .type-title--small
  &--small {
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;

    // Title/Small paragraph title – Tablet
    @media (min-width: $media-breakpoint-md) {
      font-size: 13px;
    }

    // Title/Small paragraph title – Desktop
    @media (min-width: $media-breakpoint-xl) {
      font-size: 16px;
    }

    // Title/Small paragraph title – Desktop Large
    @media (min-width: $media-breakpoint-xxl) {
      font-size: 18px;
    }
  }
}

// Text/Body paragraph
.type-p {
  font-size: 15px;
  line-height: 140%;

  // Text/Body paragraph – Tablet
  @media (min-width: $media-breakpoint-md) {
    font-size: 18px;
  }

  /* Text/Body paragraph – Desktop */
  @media (min-width: $media-breakpoint-xl) {
    font-size: 20px;
  }

  // Text/Body paragraph – Desktop Large
  @media (min-width: $media-breakpoint-xxl) {
    font-size: 25px;
  }

  // Text/Large paragraph: .type-p--large
  &--large {
    font-size: 18px;
    line-height: 125%;

    // Text/Large paragraph – Tablet
    @media (min-width: $media-breakpoint-md) {
      font-size: 24px;
    }

    // Text/Large paragraph – Desktop
    @media (min-width: $media-breakpoint-xl) {
      font-weight: 300;
      font-size: 36px;
    }

    // Text/Large paragraph – Desktop Large
    @media (min-width: $media-breakpoint-xxl) {
      font-size: 50px;
    }
  }

  // Text/Small paragraph: .type-p--small
  &--small {
    font-size: 12px;
    line-height: 140%;

    // Text/Small paragraph – Tablet
    @media (min-width: $media-breakpoint-md) {
      font-size: 13px;
    }

    // Text/Small paragraph – Desktop
    @media (min-width: $media-breakpoint-xl) {
      font-size: 16px;
    }

    // Text/Small paragraph – Desktop large
    @media (min-width: $media-breakpoint-xxl) {
      font-size: 18px;
    }
  }

  // Text/Student list: .type-p--student
  &--student {
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;

    // Text/Student list – Tablet
    @media (min-width: $media-breakpoint-md) {
      font-size: 13px;
    }

    // Text/Student list – Desktop
    @media (min-width: $media-breakpoint-xl) {
      font-size: 16px;
    }

    // Text/Student list – Desktop Large
    @media (min-width: $media-breakpoint-xxl) {
      font-size: 18px;
    }
  }

  // Text/Student list: .type-p--header
  &--header {
    font-weight: 500;
    font-size: 18px;
    line-height: 125%;

    // Text/Subhead Introduction – Tablet
    @media (min-width: $media-breakpoint-md) {
      font-size: 24px;
    }

    // Text/Subhead introduction – Desktop
    @media (min-width: $media-breakpoint-xl) {
      font-size: 36px;
    }

    // Text/Subhead introduction – Dektop Large
    @media (min-width: $media-breakpoint-xxl) {
      font-size: 50px;
    }
  }

  // Text/Subhead list: .type-p--subheader
  &--subheader {
    font-weight: 500;
    font-size: 18px;
    line-height: 125%;

    // Text/Subhead Introduction – Tablet
    @media (min-width: $media-breakpoint-md) {
      font-size: 24px;
    }

    // Text/Subhead introduction – Desktop
    @media (min-width: $media-breakpoint-xl) {
      font-size: 36px;
    }

    // Text/Subhead introduction – Dektop Large
    @media (min-width: $media-breakpoint-xxl) {
      font-size: 50px;
    }
  }
}

// Link/Body link
.type-link {
  font-weight: 500;
  font-size: 15px;
  line-height: 140%;

  // Link/Body link – Tablet
  @media (min-width: $media-breakpoint-md) {
    font-size: 18px;
  }

  // Link/Body link – Desktop large
  @media (min-width: $media-breakpoint-xxl) {
    font-size: 25px;
  }

  // Link/Footer link: .type-link--footer
  &--footer {
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;

    // Link/Footer link – Tablet
    @media (min-width: $media-breakpoint-md) {
      font-size: 13px;
    }

    // Link/Footer link – Desktop
    @media (min-width: $media-breakpoint-xl) {
      font-size: 16px;
    }

    // Link/Footer link – Desktop Large
    @media (min-width: $media-breakpoint-xxl) {
      font-size: 18px;
    }
  }

  // Link/Small parapgraph title link: .type-link--small
  &--small {
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    text-decoration-line: underline;

    // Link/Small paragraph title link – Tablet
    @media (min-width: $media-breakpoint-md) {
      font-size: 13px;
    }

    // Link/Small paragraph title link – Desktop
    @media (min-width: $media-breakpoint-xl) {
      font-size: 16px;
    }

    // Link/Small parapgraph title link – Desktop Large
    @media (min-width: $media-breakpoint-xxl) {
      font-size: 18px;
    }
  }
}

// Navigation/Main navigation
.type-nav {
  font-weight: 500;
  font-size: 27px;
  line-height: 130%;

  // Navigation/Main navigation – Desktop-Tablet
  @media (min-width: $media-breakpoint-md) {
    font-size: 40px;
  }

  // Navigation/Main navigation – Desktop Large
  @media (min-width: $media-breakpoint-xxl) {
    font-size: 50px;
  }
}

// Text/List style header
.type-list-header {
  font-size: 18px;
  line-height: 140%;
}

.type-unit-sub {
  font-size: 15px;

  @media only screen and (min-width: $media-breakpoint-md) {
    font-size: 18px;
  }

  @media only screen and (min-width: $media-breakpoint-xl) {
    font-size: 20px;
  }

  @media only screen and (min-width: $media-breakpoint-xxl) {
    font-size: 26px;
  }
}
