.ContactPage {
  width: 100%;
  padding-top: 120px;
  background-color: $color-white;
  color: $color-black;

  .close-overlay-button {
    color: $color-black;
  }

  &.theme-dark {
    color: $color-white;

    .close-overlay-button {
      color: $color-white;
    }
  }

  a {
    font-weight: 500;

    &:hover {
      text-decoration: underline;
    }
  }

  .about-intro {
    text-align: center;
    margin-bottom: 50px;

    .headline {
      text-align: center;
      margin-bottom: 25px;
    }

    .description {
      max-width: 1340px;
      text-align: center;
      margin-bottom: 30px;
    }

    .authors {
    }

    .wrapper {
      grid-column-start: 2;
      grid-column-end: span 10;
    }
  }

  .hero-image {
    width: 100%;
    margin-bottom: 60px;

    .wrapper {
      grid-column-start: 1;
      grid-column-end: span 12;
    }

    img {
      width: 100%;

      opacity: 0;
      transition: 1s opacity;

      &.lazyloaded {
        opacity: 1;
        transition: 1s opacity;
      }
    }
  }

  .intro {
    width: 100%;
    margin-bottom: 50px;

    .wrapper {
      grid-column-start: 2;
      grid-column-end: span 10;
    }

    .introduction {
      width: 100%;
      //color: $color-white;
      p {
        @extend .type-p--large;
      }
    }
  }

  .content {
    .left {
      //grid-column-start: 0;
      grid-column-end: span 12;

      .body {
        h1,
        h2,
        h3,
        h4 {
          margin-top: 25px;

          &:first-of-type {
            margin-top: 0;
          }
        }

        h3 {
          @extend .type-title--body;
        }

        margin-bottom: 60px;
      }

      .disclaimer {
        margin-bottom: 80px;

        .title {
          margin-bottom: 20px;
        }

        .bod {
          color: #828282;
        }
      }
    }

    .right {
      //grid-column-start: 2;
      grid-column-end: span 12;
      margin-bottom: 140px;

      .credits {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;

        .col {
          width: 50%;
          @extend .type-p--small;

          h1,
          h2,
          h3,
          h4 {
            margin-top: 30px;

            &:first-of-type {
              margin-top: 0;
            }
          }

          &:first-of-type {
            margin-right: 30px;
          }
        }
      }

      .additional {
        max-width: 414px;
        line-height: 140%;
        //color: $color-black;
      }
    }
  }

  @media (min-width: $media-breakpoint-md) {
    padding-top: 160px;

    .about-intro {
      margin-bottom: 80px;

      .headline {
        margin-bottom: 30px;
      }

      .description {
        margin-bottom: 35px;
      }
    }

    .intro {
      margin-bottom: 60px;
    }

    .content {
      .left {
        grid-column-start: 2;
        grid-column-end: span 5;
      }

      .right {
        grid-column-start: 8;
        grid-column-end: span 4;
        margin-bottom: 0px;
      }
    }
  }

  @media (min-width: $media-breakpoint-lg) {
    padding-top: 220px;

    .intro {
      margin-bottom: 100px;
    }

    .headline {
      margin-bottom: 60px;
    }

    .content {
      .left {
        .disclaimer {
          margin-bottom: 200px;
        }
      }
    }
  }

  @media (min-width: $media-breakpoint-xl) {
    padding-top: 250px;

    .hero-image {
      margin-bottom: 150px;
    }

    .about-intro {
      margin-bottom: 120px;

      .headline {
        margin-bottom: 60px;
      }

      .description {
        margin-bottom: 50px;
      }
    }

    .intro {
      margin-bottom: 150px;

      //.introduction {
      //	margin-bottom: 150px;
      //}
    }
  }
}
