.ProjectIntroModule {
  padding-top: 50px;

  @media only screen and (min-width: $media-breakpoint-xl) {
    padding-top: 100px;
  }

  .project-title {
    grid-column-start: 2;
    grid-column-end: span 10;
    margin-bottom: 55px;

    @media only screen and (min-width: $media-breakpoint-lg) {
      margin-bottom: 105px;
    }
  }

  .title-year {
    margin-bottom: 1em;

    & + ul {
      margin-bottom: 1.5em;

      a {
        text-decoration: underline;
      }
    }
  }

  .project-info {
    padding-bottom: 50px;

    @media only screen and (min-width: $media-breakpoint-xl) {
      display: grid;
      padding-bottom: 100px;
    }

    .wrapper {
      grid-column-start: 2;
      grid-column-end: span 3;
      margin-bottom: 70px;

      @media only screen and (min-width: $media-breakpoint-md) {
        margin-bottom: 0;
      }
    }

    a {
      text-decoration: underline;
    }

    h2 {
      margin-bottom: 1em;
      line-height: 1;
    }

    .label,
    .value {
      font-weight: 500;
      @extend .type-title--small;
    }

    .value.prizes {
      font-weight: normal;

      li {
        margin-bottom: 20px;
      }
    }

    .project-description {
      grid-column-start: 6;
      grid-column-end: span 6;
      margin-bottom: 55px;

      @media only screen and (min-width: $media-breakpoint-xl) {
        margin-bottom: 0;
      }

      ul,
      ol {
        list-style: inside;
        @extend .type-p;
      }
    }

    .row {
      border-bottom: 1px solid $color-border-on-light;

      &.hide {
        display: none;
      }

      .theme-dark & {
        border-color: $color-border-on-dark;
      }

      @media only screen and (min-width: $media-breakpoint-md) {
        padding: 20px 30px 20px 0;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  .prizes-students {
    margin-bottom: 70px;

    @media only screen and (min-width: $media-breakpoint-md) {
      margin-bottom: 150px;
    }

    @media only screen and (min-width: $media-breakpoint-xxl) {
      margin-bottom: 220px;
    }

    .spacer {
      grid-column-start: 2;
      grid-column-end: span 4;
      border-top: 1px solid $color-border-on-light;

      .theme-dark & {
        border-color: $color-border-on-dark;
      }
    }

    .wrapper {
      grid-column-start: 6;
      grid-column-end: span 6;
      display: flex;
      border-top: 1px solid $color-border-on-light;
      flex-direction: column;

      .theme-dark & {
        border-color: $color-border-on-dark;
      }

      .prizes {
        @media only screen and (min-width: $media-breakpoint-sm) {
          column-count: 2;
        }

        @media only screen and (min-width: $media-breakpoint-md) {
          column-count: 3;
        }
      }
    }

    .title {
      margin-top: 50px;
      margin-bottom: 20px;

      @media only screen and (min-width: $media-breakpoint-md) {
        margin-top: 90px;
        margin-bottom: 35px;
      }
    }

    .student {
      @extend .type-link--small;
      display: block;
    }

    .award,
    .year {
      @extend .type-p--small;
      display: block;
    }

    .prizes {
      li {
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;

        @media only screen and (min-width: $media-breakpoint-md) {
          display: inline-block;
          width: 100%;
        }
      }
    }
  }

  .row-wrapper {
    display: flex;
    border-bottom: 1px solid $color-border-on-light;

    .theme-dark & {
      border-color: $color-border-on-dark;
    }
  }

  .row {
    display: flex;
    padding: 20px 30px 20px 0;

    .label {
      opacity: 0.5;
      flex: 0 0 30%;
    }

    .value {
      padding-left: 20px;
    }
  }
}
